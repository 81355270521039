<template>
    <div>
        <header-portrate />
    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        <v-row class="m-2">
            <v-col>
                <v-btn 
                    v-b-toggle.add_users
                    style="background: green !important;color:#FFF !important;margin-top:23px;float:left">
                    {{lang.add_user}}</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.counter }}</td>
                            <td>{{ row.item.full_name }}</td>
                            <td>{{ row.item.username }}</td>
                            <td nowrap>{{ row.item.permission }}</td>
                            <td nowrap>{{ row.item.last_login_date }}</td>
                            <td nowrap="">
                            <i class="fas fa-pencil-alt ma-1" style="cursor:pointer;font-size:1.2rem;color:#222" @click="ViewItem(row.item)"></i>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <addUser />
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import addUser from '@/components/addUser.vue';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
  components: { addUser, BreadCrumbs, TabsComp, HeaderPortrate, Footer },
    data() {
        return {
            active_tab:0,
            
            
            
            tablerows: [

            ],
        }
    },
    methods: {
        ViewItem(item){
            this.$router.push({path: '/updateuser/'+item.id})
        },
        getUsers(){
            const post = new FormData();
            post.append("type" , 'getUsers');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[user]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log(res);
                    this.tablerows = res.results.data.results;
                }
            )
        }
    },
    created() {
        this.getUsers();
        setInterval(
            () => {this.getUsers();},6000
        )
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function() {
            return [
                {
                    index:0,name:this.lang.system_settings,href:'/settings/system-settings', class: 'mytab1'
                },
                {
                    index:1,name:this.lang.users,href:'/settings/users-settings', class: 'mytab'
                },
                {
                    index:2,name:this.lang.permission,href:'/settings/permissions-settings', class: 'mytab3'
                }
            ]
        },
        items:function () {
                
            return {
                text: this.lang.users,
                disabled: true,
                to: '/settings/users-settings',
            }
        },
        headers: function(){
            return [
                {
                    text: '',
                    align: 'center',
                    filterable: false,
                    value: 'counter',
                },
                {
                    text: this.lang.user_name,
                    align: 'center',
                    filterable: false,
                    value: 'full_name',
                },
                {
                    text: this.lang.login_name,
                    align: 'center',
                    filterable: false,
                    value: 'username',
                },
                {
                    text: this.lang.permission,
                    align: 'center',
                    filterable: false,
                    value: 'permission',
                },
                {
                    text: this.lang.last_login,
                    align: 'center',
                    filterable: false,
                    value: 'last_login_date',
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                },
            ]
        },
    },
}
</script>